import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullDateToMonthDate',
})
export class FullDateToMonthDatePipe implements PipeTransform {
  transform(date: string) {
    const month = date.split(',')[0];
    const year = date.split(',')[1];

    const fullDate = {
      month: month,
      year: year,
    };

    return fullDate;
  }
}
