import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { AdDirective, GroupItemMessageTimePipe } from '@goatsports/shared/util';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterAttributeTypesPipe } from './pipes/filter-attribute-types.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { FullDateToMonthDatePipe } from './pipes/full-date-to-month-date.pipe';
import { BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
@NgModule({
  declarations: [FilterAttributeTypesPipe, FullDateToMonthDatePipe],
  imports: [
    CommonModule,
    GroupItemMessageTimePipe,
    AdDirective,
    ButtonModule,
    DialogModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    BreadcrumbModule,
  ],
  exports: [
    AdDirective,
    GroupItemMessageTimePipe,
    FilterAttributeTypesPipe,
    FullDateToMonthDatePipe,
    ButtonModule,
    DialogModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    BreadcrumbModule,
  ],
})
export class SharedModule {}
