import { Pipe, PipeTransform } from '@angular/core';
import {
  ExerciseAttributeModel,
  ExerciseAttributeTypeModel,
} from '@goatsports/core/data-access';

@Pipe({
  name: 'filterAttributeTypes',
})
export class FilterAttributeTypesPipe implements PipeTransform {
  transform(
    exerciseAttributeTypes: ExerciseAttributeTypeModel[],
    exerciseAttributes: ExerciseAttributeModel[]
  ) {
    const exerciseAttributeTypeUids = new Map(
      exerciseAttributes.map((attribute) => [
        attribute.exerciseAttributeTypeUid,
        true,
      ])
    );
    return exerciseAttributeTypes.filter(
      (type) => !exerciseAttributeTypeUids.has(type.uid)
    );
  }
}
